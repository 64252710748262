import { formatDate, removeHTMLTags } from '../../../helpers';
import { OrderActivityMedia, OrderActivitySenderType } from '../../../types';

import { BaseModel } from './base.model';

export class OrderActivityModel extends BaseModel<any> {
  senderType: OrderActivitySenderType = this._data.senderType;
  medias: OrderActivityMedia[] = this._data.medias;

  get key() {
    return this._data.id || this._data.createdAt;
  }

  get image() {
    return this._data?.imageUrl || this._data?.image;
  }

  get message() {
    return removeHTMLTags(this._data.message);
  }

  get rawMessage() {
    return this._data.message;
  }

  get date() {
    return formatDate(this._data.createdAt);
  }

  get createdAtTime() {
    return new Date(this._data.createdAt).getTime();
  }

  get messageUserType() {
    return this._data.source?.toLowerCase();
  }

  get type() {
    const activityType: string = this._data?.activityType || this._data?.type;
    return activityType.toLowerCase();
  }

  get username() {
    return this._data.username;
  }

  get fullName() {
    return this._data?.senderName;
  }
}
